@import "_generated-font-mixins.scss";
.ad__header {
  @media screen and (width <= 640px) {
    padding: 0;
  }

  align-items: center;
  padding: 30px 0;
  background: 0 0;

  & h1 {
    @media screen and (width <= 640px) {
      float: left;

      width: 100%;
      margin-bottom: 20px;

      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      color: #333;
    }

    @media print {
      margin-top: 25px;
    }

    display: inline;

    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    word-wrap: break-word;
    vertical-align: top;
  }

  &__favorite {
    @media print {
      margin-top: 25px;
    }

    & i {
      transition: transform 0.2s ease;
    }
  }
}

.fill__heart {
  margin-left: 9px;

  &::after {
    @include icon-fill-heart;

    transform: scale(1);
    font-size: 24px;
    color: #ef4825;
  }
}

.heart {
  margin-left: 9px;

  &::after {
    @include icon-heart;

    transform: scale(0.8);
    font-size: 24px;
    color: #a2a6ab;
  }
}

.mobile {
  position: absolute;
  z-index: 999;
  top: -67px;
  right: 4px;

  width: 42px;
  height: 51px;

  background-color: #ececef;
}
