@import "_generated-font-mixins.scss";
.popup__background {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: rgb(0 0 0 / 75%);
}

.popup__content {
  position: relative;
  width: 286px;
  background: #eaf0ff;
  border-radius: 8px;

  &__body {
    padding: 28px 24px 32px;
    background: #fff;
    border-radius: 8px 8px 40px 40px;
  }

  &__title {
    margin-bottom: 3px;

    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    color: #333;
  }

  &__description {
    min-height: 57px;
    margin-bottom: 7px;

    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #000;
  }

  & img {
    display: block;
    margin: 0 auto;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 24px 20px;
  }
}

.link {
  cursor: pointer;
  font-size: 15px;

  &__tariff {
    margin-bottom: 17px;
    padding: 10px 40px 7px;

    color: #fff;

    background: #3b75ff;
    border-radius: 4px;
  }

  &__subscriptions {
    line-height: 17px;
    color: #3b75ff;
  }
}

.close__button {
  &::before {
    @include icon-close;

    cursor: pointer;

    position: absolute;
    top: 3px;
    right: 5px;

    color: #333;

    opacity: 0.65;
    background-repeat: no-repeat;
  }
}
