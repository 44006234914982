@import "_generated-font-mixins.scss";
@mixin link-styles {
  color: #005cc3;
  text-decoration: none;
}

.description {
  @media screen and (width <= 640px) {
    margin: 0;
    padding: 20px 0;

    line-height: 21px;
    color: #333;
    word-break: break-word;
  }

  max-width: 720px;
  margin-top: 10px;
  margin-bottom: 30px;

  font-size: 15px;
  line-height: 147%;
  word-break: break-word;
  white-space: pre-wrap;

  &__ofer {
    @media screen and (width <= 640px) {
      display: none;
    }

    display: block;
    color: #757575;

    & a {
      @include link-styles;
    }

    & img {
      display: none;
    }
  }

  & a {
    @include link-styles;
  }

  &__html {
    @media screen and (width <= 640px) {
      margin: 0 10px;
      padding: 20px 0;

      line-height: 21px;
      color: #333;
      word-break: break-word;
    }

    margin-bottom: 30px;
    padding-top: 30px;
    font-size: 15px;
    line-height: 147%;

    & img {
      display: none;
    }

    & strong {
      font-size: 17px !important;
      font-weight: 700;
      color: #a2a6ab;
    }

    & table {
      @media screen and (width >= 576px) {
        max-width: 720px;
      }

      table-layout: fixed;
      width: 100%;

      & img,
      br {
        display: none;
      }

      & tr {
        display: block;
      }

      & td {
        display: inline-block;
        text-align: left !important;
        background-color: transparent !important;
      }

      & strong {
        display: block;

        margin-bottom: 7px;

        font-size: 17px !important;
        font-weight: 700;
        color: #333;
        letter-spacing: -0.5pt;

        & span {
          font-size: 17px !important;
          color: #333 !important;
        }
      }

      & span {
        font-size: 15px !important;
        color: #000 !important;
      }

      & h3 {
        & strong {
          display: block;

          margin-bottom: 10px;

          font-size: 17px !important;
          font-weight: 700;
          color: #333;
          letter-spacing: -0.5pt;

          & span {
            font-size: 17px !important;
          }
        }

        & span {
          color: #000 !important;
        }

        & ~ span {
          display: block;
          margin-bottom: 15px;
          font-size: 15px !important;
          color: #000 !important;
        }
      }
    }
  }
}
